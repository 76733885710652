import type {
    Recruiter,
    RecruiterId,
    RecruiterResponse,
} from '../types/company';

const ENDPOINT = '/api/manage-talents/company';

const currentUser = ref<Recruiter>();
const recruiterList = ref<Array<Recruiter>>();

export const useCompany = () => {
    const getRecruiterList = async () => {
        const result = await fetchOrRedirect<RecruiterResponse>(
            `${ENDPOINT}/recruiter`,
        );
        if (!result?.error) {
            currentUser.value = result.data?.currentUser;
            recruiterList.value = result.data?.colleagues;
        }
        return result?.data;
    };

    const getCurrentUserId = async () => {
        const result = await fetchOrRedirect<RecruiterId>(
            `${ENDPOINT}/current-company-project-user`,
        );
        if (!result?.error && result.data?.recruiterId) {
            currentUser.value = { recruiterId: result.data?.recruiterId };
        }
    };

    const hasOneOfTheRecruitersNoLongerAnActiveLicence = (
        recruiterIds: number[] | undefined,
    ): boolean => {
        const activeLicenceIds =
            recruiterList?.value?.map((recruiter) => recruiter.recruiterId) ||
            [];

        // Return false if there are sharedRecruiter but recruiterIds is empty
        if (recruiterIds && activeLicenceIds.length === 0) {
            return false;
        }
        // Checks if all elements in shareSettings.recruiterIds exist in recruiterIds
        // Default is true -> shareSettings.recruiterIds is undefined, the note will not be shared
        return (
            recruiterIds?.every((id) => activeLicenceIds.includes(id)) ?? true
        );
    };

    return {
        currentUser,
        getCurrentUserId,
        getRecruiterList,
        hasOneOfTheRecruitersNoLongerAnActiveLicence,
        recruiterList,
    };
};
